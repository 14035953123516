<template>
  <div class="container">
    <!-- 查询条件 -->
    <analysis-query v-if="!$route.query.xid" @ready="batchParams"></analysis-query>

    <div class="head-item">
      <!-- 姓名 -->
      <div class="item-content">
        <label>客户：</label>
        <el-input style="display: inline-block; width: 160px"
          v-model="customerName" placeholder="请输入客户姓名"></el-input>
      </div>
    </div>

    <!-- 表格 -->
    <el-table class="table-style"
      ref="multipleTable"
      tooltip-effect="dark"
      header-row-class-name="table-header-style"
      :data="tableData.filter(e => !customerName || (e.nickname && e.nickname.indexOf(customerName) != -1))"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <!-- 头像 -->
      <el-table-column label="头像" prop="name" align="center" width="60px">
        <template slot-scope="scope">
          <img class="head-img" :src="scope.row.avatar || $configs.headImg">
        </template>
      </el-table-column>
      <!-- 姓名 -->
      <el-table-column label="姓名" prop="nickname" align="center" width="120px"></el-table-column>
      <!-- 电话 -->
      <el-table-column label="电话" align="center" width="180px">
        <template slot-scope="scope">
          {{scope.row.tel || '--'}}
        </template>
      </el-table-column>
      <!-- 时间 -->
      <el-table-column label="时间" align="center" width="240px">
        <template slot-scope="scope">
          <span>{{scope.row['@timestamp'] | dateFormat}}</span>
        </template>
      </el-table-column>
      <!-- 线索 -->
      <el-table-column label="线索" prop="rt" align="left">
        <template slot-scope="scope">
          {{handleText(scope.row)}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AnalysisQuery from '@/websites/cms/components/AnalysisQuery'
import ResourceDialog from '@/websites/cms/js/dialog'
export default {
  components: {
    'analysis-query': AnalysisQuery
  },
  data(){
    return {
      tableData: [],
      query: {
        size: 1000,
        after: '',
      },
      body: null,
      customerName: ''
    }
  },
  created(){
    if(this.$route.query.xid){
      let params = this.$route.query;
      params.ranges = JSON.parse(params.ranges);
      this.getUserEvtList(params);
    }
  },
  methods: {
    // 组装查询数据
    async batchParams(data){
      this.tableData = [];
      this.query.after = '';
      let body = {rt: data.sourceType, cid: data.companyId};
      if(data.companyId != data.teamId) body.tid = data.teamId;
      if(this.$route.query.xid) body.xid = this.$route.query.xid;
      body.ranges = [{from: data.times[0], to: this.$dayjs(data.times[1]).add(1, 'day').format('YYYY-MM-DD')}];
      this.getUserEvtList(body);
      this.body = body;
    },

    // 查询用户行为列表
    async getUserEvtList(body){
      let params = null;
      params = body ? body : this.body;
      let res = await this.$ZGManager.statCustomerEvents(params, this.query);
      if(res.status != 200) return;
      this.query.after = res.data.list.length < 1 ? '' : res.data.after
      res.data.list.map(e => this.tableData.push(e));
    },

    // 处理线索文字
    handleText(item){
      let text = "暂无内容";
      switch(item.rt){
        case 'card': text = `${item.nickname}查看了您的名片`; break;
        case 'video': text = `${item.nickname}查看了您的视频`; break;
        case 'site': text = `${item.nickname}查看了您的官网`; break;
        case 'article': text = `${item.nickname}查看了您的文章`; break;
      };
      return text;
    },

    filterHandler(value, row, column){
      return row.name == this.customerName;
    }
  }
}
</script>

<style lang="scss" scoped>
.head-item{
  margin-bottom: 10px;
  .item-content{
    display: inline-block;
    margin-right: 30px;
    label{
      font-size: 14px;
      color: #666;
      margin-right: 5px;
    }
    ::v-deep .el-select{
      width: 160px;
    }
    ::v-deep .el-date-editor{
      width: 406px;
    }
  }
}
.table-style{
  width: 100%; 
  margin-top: 10px;
  .head-img{
    width: 40px;
    height: 40px;
  }
}
</style>