<template>
  <div class="analysis-query">
    <div class="head-item">
      <!-- 部门 -->
      <div class="item-content">
        <label>部门：</label>
        <el-select v-model="params.teamId" placeholder="请选择部门">
          <el-option v-for="item in teamsList"
            :key="item.id"
            :label="item.label" 
            :value="item.id">
          </el-option>
        </el-select>
      </div>

      <!-- 来源 -->
      <div class="item-content">
        <label>来源：</label>
        <el-select v-model="params.sourceType" placeholder="请选择资源类型">
          <el-option v-for="item in sourceList" :key="item.val"
            :label="item.name"
            :value="item.val">
          </el-option>
        </el-select>
      </div>

      <!-- 时间 -->
      <div v-if="type=='default'" class="item-content">
        <label>时间：</label>
        <el-date-picker v-model="params.times" type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false">
        </el-date-picker>
      </div>

      <!-- 姓名 -->
      <div v-if="type!='default'" class="item-content">
        <label>员工：</label>
        <el-input style="display: inline-block; width: 195px"
          v-model="params.memberName" placeholder="请输入员工姓名"></el-input>
      </div>

      <!-- 查询 -->
      <el-button type="primary" icon="el-icon-search" @click="emitRequest">查 询</el-button>
    </div>

    <div v-if="type!='default'" class="head-item">
      <!-- 时间 -->
      <div class="item-content">
        <label>时间：</label>
        <el-date-picker v-model="params.times" type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false">
        </el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    }
  },
  data(){
    return {
      params: {
        companyId: '',
        teamId: '',
        sourceType: 'card',
        times: [
          this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
        memberName: ''
      },
      sourceList: [                                  // 资源列表
        {name: '名片', val: 'card'},
        {name: '文章', val: 'article'},
        {name: '官网', val: 'site'},
        {name: '视频', val: 'video'}
      ],
      teamsList: [],                                 // 部门列表
    }
  },
  mounted(){
    this.getCompany();
  },
  methods: {
    // 获取公司信息
    async getCompany(){
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200){
        this.teamsList.push({
          id: res.data.id,
          label: res.data.shortName || '全部人员',
        });
        this.params.teamId = this.params.companyId = res.data.id;
        this.getMyTeams();
      }
    },

    // 获取我的团队列表
    async getMyTeams(){
      let res = await this.$ZGManager.getMyTeams();
      if(res.status == 200){
        res.data.map((e) => {
          this.teamsList.push({
            id: e.id,
            label: e.name,
          });
        });
      }
      this.emitRequest();
      
    },

    // 发出查询请求
    emitRequest(){
      if(!this.params.times) return this.$message.fail('请选择查询时间');
      this.$emit('ready', this.params);
    }
  }
}
</script>

<style lang="scss" scoped>
.head-item{
  margin-bottom: 10px;
  .item-content{
    display: inline-block;
    margin-right: 30px;
    label{
      font-size: 14px;
      color: #666;
      margin-right: 5px;
    }
    ::v-deep .el-select{
      width: 160px;
    }
    ::v-deep .el-date-editor{
      width: 406px;
    }
  }
}
</style>